import SignUpBase from './SignUpBase';

class PractitionerSignUpBase extends SignUpBase {
  static name() { return 'PractitionerSignUpBase'; }

  constructor(props, el) {
    super(props, el);
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
    };

    this.validatePromoCodeAsync = async () => {
      const { $promoField } = this.objects;
      const element = $promoField.get(0);
      const codeVal = $promoField.val().toUpperCase();

      if (codeVal.trim().length > 0) {
        try {
          const response = await $.ajax({
            url: '/checkpromocode/',
            type: 'POST',
            data: {
              regPromo: codeVal,
            },
          });

          const rtnValue = JSON.stringify(response).substring(1, 2);
          if (rtnValue !== '1') {
            element.setCustomValidity('This promo code is invalid.');
            return false;
          }
        }
        catch (error) {
          console.error('Error validating promo code:', error);
          element.setCustomValidity('There was an error validating the promo code.');
          return false;
        }
      }

      element.setCustomValidity('');
      return true;
    };

    // handles standared form submission for each step
    // validates inputs submits data to hubspot and calls submitStepCallback on success

    this.handleStepSubmitAsync = async (
      e,
      $stepSubmitButton,
      submitStepCallback,
      validationCallbackAsync,
      sendToHubSpotCallback,
    ) => {
      const {
        $form,
      } = this.objects;

      e.preventDefault();
      e.stopPropagation();

      this.showSpinner();
      $stepSubmitButton.prop('disabled', true);

      // Make sure we run though all checks so all possible error messages are displayed
      const customChecksOK = validationCallbackAsync
        ? await validationCallbackAsync()
        : true;
      const mainChecksOK = $form.get(0).checkValidity();
      $form.addClass('was-validated');
      const isOK = mainChecksOK && customChecksOK;
      if (isOK) {
        if (sendToHubSpotCallback) {
          sendToHubSpotCallback();
        }

        submitStepCallback();
      }
      else {
        $stepSubmitButton.prop('disabled', false);
        this.hideSpinner();
      }
    };
  }
}

export default PractitionerSignUpBase;
