import AccordianItem from './AccordianItem';

class AccordianContainer {
  static name() { return 'AccordianContainer'; }

  static refreshable() { return true; }

  constructor({ allowClose, hideToggle }, el) {
    this.allowClose = allowClose;
    this.hideToggle = hideToggle;
    this.el = el;
    this.items = [];
  }

  init() {
    console.info('~~~ Accordian Container ~~~');

    const accordianItems = this.el.getElementsByClassName('accordian-item');
    // skip processing if empty
    if (!accordianItems.length) return;
    // create items
    this.items = Array.from(accordianItems).map(item => new AccordianItem(item, this));
    // check if an item must be open
    if (!this.allowClose) {
      this.items[0].open();
    }
  }

  closeAccordianItems() {
    this.items.forEach(item => item.close());
  }
}

export default AccordianContainer;
