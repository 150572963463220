import PractitionerSignUpBase from './PractitionerSignUpBase';

class PractitionerSetup extends PractitionerSignUpBase {
  static name() { return 'PractitionerSetup'; }

  constructor(props, el) {
    super(props, el);
    this.props = props;
    this.el = el;
    this.$el = $(el);
    this.stripe = window.Stripe(this.$el.data('stripe-publishable-key'));

    this.objects = {
      $form: this.$el.find('[data-form]'),
      $listServicesField: this.$el.find('#listServices'),
      $productsField: this.$el.find('#products'),
      $consentField: this.$el.find('#consent'),
      $step3Header: this.$el.find('[data-step-3-header]'),
      $step3Background: this.$el.find('[data-step-3-background]'),
      $step3: this.$el.find('[data-step-3]'),
      $step3Submit: this.$el.find('[data-step-3]').find('[data-submit]'),
      $step3ErrorMessages: this.$el.find('[data-step-3]').find('[data-error-messages]'),
      $step3ErrorMessageTemplate: this.$el.find('[data-step-3]').find('[data-error-messages-template]'),
      $step4Header: this.$el.find('[data-step-4-header]'),
      $step4Background: this.$el.find('[data-step-4-background]'),
      $step4: this.$el.find('[data-step-4]'),
      $step4Submit: this.$el.find('[data-step-4]').find('[data-submit]'),
      $step4ErrorMessages: this.$el.find('[data-step-4]').find('[data-error-messages]'),
      $step4ErrorMessageTemplate: this.$el.find('[data-step-4]').find('[data-error-messages-template]'),
      $stepProgress: this.$el.find('[data-step-progress]'),
    };

    this.handleStep3SubmitAsync = async e => this.handleStepSubmitAsync(
      e,
      this.objects.$step3Submit,
      this.submitStep3,
    );

    this.handleStep4SubmitAsync = async e => this.handleStepSubmitAsync(
      e,
      this.objects.$step4Submit,
      this.submitStep4,
      null,
      this.sendRegistrationPage4ToHubSpot,
    );

    this.sendRegistrationPage1ToHubSpot = () => {
      const {
        $firstNameField,
        $lastNameField,
        $emailField,
        $phoneField,
      } = this.objects;

      const hscookie = this.getCookie('hubspotutk');
      const dt = new Date().setUTCHours(0, 0, 0, 0);

      const formData = {
        fields: [
          { name: 'email', value: $emailField.val() },
          { name: 'firstname', value: $firstNameField.val() },
          { name: 'lastname', value: $lastNameField.val() },
          { name: 'provider_sign_up_phone_number', value: $phoneField.val() },
          { name: 'date_signed_up', value: dt },
          { name: 'type_of_contact', value: 'Did not accept T&C\'s' },
          { name: 'profile_pic_added', value: 'No' },
          { name: 'professional_statement_added', value: 'No' },
        ],
        context: {
          hutk: hscookie,
          pageUri: 'www.sofiahealth.com/signup_practitioner',
          pageName: 'Signup',
        },
      };

      this.logHS(formData); // Log it for the record
    };

    this.sendRegistrationPage2ToHubSpot = () => {
      const { $emailField, $agreeField } = this.objects;
      const hscookie = this.getCookie('hubspotutk');
      const dt = new Date().setUTCHours(0, 0, 0, 0);

      const formData = {
        fields: [
          { name: 'email', value: $emailField.val() },
          { name: 'agree_to_terms_and_conditions_website', value: $agreeField.val() },
          { name: 'type_of_contact', value: 'Accepted T & C\'s' },
          { name: 'subscription_start_date', value: dt },
        ],
        context: {
          hutk: hscookie,
          pageUri: 'www.sofiahealth.com/signup_practitioner_tc',
          pageName: 'Signup_TC',
        },
      };

      this.logHS(formData); // Log it for the record
    };

    this.submitStep3 = () => this.submitStep(
      this.objects.$listServicesField.prop('checked')
        ? this.objects.$step3Submit.data('services-url')
        : this.objects.$step3Submit.data('products-url'),
      this.objects.$step3Submit,
      this.objects.$step3ErrorMessages,
      this.objects.$step3ErrorMessageTemplate,
      this.showStep4,
    );

    this.submitStep4 = () => this.submitStep(
      this.objects.$step4Submit.data('submit-url'),
      this.objects.$step4Submit,
      this.objects.$step4ErrorMessages,
      this.objects.$step4ErrorMessageTemplate,
      this.sendToStripe,
    );

    this.showStep3 = () => {
      const {
        $step3Header,
        $step3Background,
        $step3,
        $step3ErrorMessages,
        $step3Submit,
        $step4Header,
        $step4Background,
        $step4,
        $step4ErrorMessages,
        $step4Submit,
        $stepProgress,
      } = this.objects;
      $step3Submit.prop('disabled', false);
      $step3Header.removeClass('d-none');
      $step3Background.removeClass('d-none');
      $step3.removeClass('d-none');
      $step3ErrorMessages.html('');
      $step4Submit.prop('disabled', true);
      $step4ErrorMessages.html('');
      $step4Header.addClass('d-none');
      $step4Background.addClass('d-none');
      $step4.addClass('d-none');
      $stepProgress.removeClass('active');
      $($stepProgress.get(2)).addClass('active');
      this.hideSpinner();
    };

    this.showStep4 = () => {
      const {
        $step3Header,
        $step3Background,
        $step3,
        $step3ErrorMessages,
        $step3Submit,
        $step4Header,
        $step4Background,
        $step4,
        $step4ErrorMessages,
        $step4Submit,
        $stepProgress,
        $listServicesField,
      } = this.objects;

      if (!$listServicesField.prop('checked')) {
        window.location.href = $step4Submit.data('product-redirect-url');
      }
      else {
        $step3Submit.prop('disabled', true);
        $step3Header.addClass('d-none');
        $step3Background.addClass('d-none');
        $step3.addClass('d-none');
        $step3ErrorMessages.html('');
        $step4Submit.prop('disabled', true);
        $step4ErrorMessages.html('');
        $step4Header.removeClass('d-none');
        $step4Background.removeClass('d-none');
        $step4.removeClass('d-none');
        $stepProgress.removeClass('active');
        $($stepProgress.get(3)).addClass('active');
        this.hideSpinner();
      }
    };

    this.sendToStripe = (json) => {
      const { session } = json;
      if (session) {
        // Call Stripe.js method to redirect to the new Checkout page
        this.stripe.redirectToCheckout({
          sessionId: session.id,
        })
          .then(this.handleResult);
      }
    };

    // Handle any errors returned from Checkout
    this.handleResult = (result) => {
      const { $step4ErrorMessages, $step4ErrorMessageTemplate, $step4Submit } = this.objects;

      if (result.error) {
        this.renderErrorMessages(['Oops! We have encountered an error.'], $step4ErrorMessages, $step4ErrorMessageTemplate);
        $step4Submit.prop('disabled', false);
      }

      console.log(result);
    };
  }

  init() {
    console.info('~~~ PractitionerSetup ~~~');
    const {
      $step3Submit,
      $step4Submit,
      $consentField,
    } = this.objects;

    $consentField.on('click', function () {
      $step4Submit.prop('disabled', !$(this).is(':checked'));
    });

    $step3Submit.on('click', this.handleStep3SubmitAsync);
    $step4Submit.on('click', this.handleStep4SubmitAsync);

    this.showStep3();
  }
}

export default PractitionerSetup;
