class SignUpForm {
  static name() { return 'SignUpForm'; }

  constructor(props, el) {
    this.props = props;
    this.el = el;

    this.objects = {};

    this.handleGlobalValidation = () => {
      // TODO setup utils for global messaging
      if (!this.el.checkValidity() && this.el.dataset.globalMessage) {
        document.querySelector(this.el.dataset.globalMessage).style.display = 'block';
      }
    };
  }

  init() {
    console.info('~~~ SignUpForm ~~~');

    document.getElementById('signUp').addEventListener('click', this.handleGlobalValidation);
  }
}

export default SignUpForm;
